<template>
  <b-row>
    <b-col cols="12" class="mb-1">
      <h2
        class="content-header-title float-left pr-1 mb-0"
        style="border-right: 0px"
      >
        {{ T("Web.Generic.Settings") }}
      </h2>
    </b-col>
    <b-col cols="8" sm="12" md="6">
      <b-card>
        <b-form-group :label="T('Web.Generic.Email', 'Email')">
          <b-form-input disabled :value="userProfile.email" />
        </b-form-group>
      </b-card>
      <b-overlay :show="languageIsUpdating">
        <b-card>
          <b-form-group :label="T('Web.UserSettings.Language', 'Language')">
            <b-form-select
              @change="setSelectedLang()"
              class="form-control"
              v-model="selectedLanguageKey"
            >
              <b-form-select-option value="en">
                {{ T("Web.Generic.En", "English") }}
              </b-form-select-option>
              <b-form-select-option value="DK">{{
                T("Web.Generic.Dk", "Dansk")
              }}</b-form-select-option>
              <b-form-select-option value="NO">{{
                T("Web.Generic.No", "Norsk")
              }}</b-form-select-option>
              <b-form-select-option value="SE">
                {{ T("Web.Generic.Se", "Svensk") }}
              </b-form-select-option>
            </b-form-select>
            <b-form-checkbox class="mt-1" v-model="updateAllAccountsOption">
              {{
                T(
                  "Web.UserSettings.SetLanguageForAllAccounts",
                  "Update language for all accounts"
                )
              }}
            </b-form-checkbox>
          </b-form-group>
          <b-button
            class="float-right"
            variant="primary"
            @click="updateLanguage"
            ><feather-icon icon="SaveIcon" />
            {{ T("Web.Generic.Update") }}</b-button
          >
        </b-card>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import {
  BContainer,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BFormCheckbox,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BContainer,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BFormCheckbox,
    BButton,
    BOverlay,
  },
  data() {
    return {
      languageIsUpdating: false,
      selectedLanguageKey: null,
      updateAllAccountsOption: true,
    };
  },
  mounted() {
    this.selectedLanguageKey = this.userProfile.language;
  },
  computed: {
    ...mapGetters({
      userProfile: "appUser/getCurrentProfile",
      accountScope: "appUser/getCurrentAccountScope",
    }),
  },
  methods: {
    ...mapActions({
      updateUserLanguage: "appUser/updateLanguage",
    }),
    async updateLanguage() {
      this.languageIsUpdating = true;
      try {
        await this.updateUserLanguage({
          languageKey: this.selectedLanguageKey,
          updateOnAllAccounts: this.updateAllAccountsOption,
        });
      } catch {
        alert("Error");
      } finally {
        this.languageIsUpdating = false;
      }
    },
  },
};
</script>